<template>
  <v-container class="grey lighten-3">
    <v-row class="flex-row-reverse">
      <v-col
          cols="12"
          lg="5"
          md="5"
      >
        <v-sheet rounded="lg">
          <v-card
              elevation="2"
              class="mb-4 text-center"
              outlined
          >

            <div v-if="introductionVideo" class="embed-responsive embed-responsive-16by9 mb-4">
              <iframe class="embed-responsive-item"
                      :src="introductionVideo+'?autoplay=1'"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen></iframe>
            </div>
            <div v-else>
              <v-img
                  :aspect-ratio="16/9"
                  :src="course.photo"
              ></v-img>
            </div>

            <v-chip
                v-if="totalVideos"
                class="ma-2 pa-3"
                color="indigo"
                text-color="white"
            >
              <v-avatar left>
                <v-icon>mdi-video</v-icon>
              </v-avatar>
              {{ totalVideos }} video lectures
            </v-chip>
            <v-chip
                v-if="totalExams"
                class="ma-2 pa-3"
                color="blue"
                text-color="white"
            >
              <v-avatar left>
                <v-icon>mdi-newspaper</v-icon>
              </v-avatar>
              {{ totalExams }} exams
            </v-chip>
            <v-chip
                v-if="totalPdfs"
                class="ma-2 pa-3"
                color="green"
                text-color="white"
            >
              <v-avatar left>
                <v-icon>mdi-file-pdf</v-icon>
              </v-avatar>
              {{ totalPdfs }} PDF Books
            </v-chip>

            <v-chip
                v-if="totalNotes"
                class="ma-2 pa-3"
                color="orange"
                text-color="white"
            >
              <v-avatar left>
                <v-icon>mdi-note-outline</v-icon>
              </v-avatar>
              {{ totalNotes }} Notes
            </v-chip>
          </v-card>

          <v-card
              elevation="2"
              class="mx-auto mb-4 d-lg-none"
              outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="display-1 mb-1 text-wrap">
                  {{ course.title }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-wrap">{{ course.sub_title }}</v-list-item-subtitle>

                <div v-if="course" class="d-flex align-baseline">
                  <v-btn
                      depressed
                      color="success"
                      class="mr-2 my-2"
                      text
                  >
                    <div v-if="course.discount">
                      ৳{{ price }} <span class="red--text"><del v-if="course.is_paid">৳{{ course.price }}</del></span>
                    </div>
                    <div v-else>
                      ৳{{ price }}
                    </div>
                  </v-btn>
                  <div v-if="course.users_count" class="caption grey--text">
                    <v-icon small v-text="'mdi-account-multiple'"></v-icon>
                    {{ course.users_count }}
                  </div>
                </div>
                <div v-if="course">
                  <v-btn
                      depressed
                      color="success"
                      class="mr-2 my-2"
                      :to="{name: 'Pay',params:{courseId:$route.params.courseId, course: course}}"
                      small
                      v-if="!free"
                      v-show="!subscribed"
                  >
                    Buy this course
                  </v-btn>
                  <v-btn
                      depressed
                      color="success"
                      class="mr-2 my-2"
                      small
                      v-else
                      @click="enroll"
                      v-show="!subscribed"
                  >
                    Enroll
                  </v-btn>
                  <v-btn
                      depressed
                      color="primary"
                      class="mr-2 my-2"
                      :to="{name: 'Course',params:{courseId:$route.params.courseId}}"
                      small
                  >
                    View course
                  </v-btn>
                </div>
                <div v-else>
                  <v-skeleton-loader
                      type="article"
                  ></v-skeleton-loader>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>

          <v-card
              elevation="2"
              class="mx-auto mb-4"
              outlined>

            <course-menu-preview :course="course" :topics="topics"></course-menu-preview>

            <v-divider class="mx-4"></v-divider>

            <div v-if="course" class="pa-4">
              <v-btn
                  depressed
                  color="success"
                  class="mr-2 my-2"
                  text
              >
                <div v-if="course.discount">
                  ৳{{ price }} <span class="red--text"><del v-if="course.is_paid">৳{{ course.price }}</del></span>
                </div>
                <div v-else>
                  ৳{{ price }}
                </div>
              </v-btn>

              <v-btn
                  depressed
                  color="success"
                  class="mr-2 my-2"
                  :to="{name: 'Pay',params:{courseId:$route.params.courseId, course: course}}"
                  small
                  v-if="!free"
                  v-show="!subscribed"
              >
                Buy this course
              </v-btn>
              <v-btn
                  depressed
                  color="success"
                  class="mr-2 my-2"
                  small
                  v-else
                  @click="enroll"
                  v-show="!subscribed"
              >
                Enroll
              </v-btn>
              <v-btn
                  depressed
                  color="primary"
                  class="mr-2 my-2"
                  :to="{name: 'Course',params:{courseId:$route.params.courseId}}"
                  small
              >
                View course
              </v-btn>
            </div>
            <div v-else>
              <v-skeleton-loader
                  type="article"
              ></v-skeleton-loader>
            </div>
          </v-card>
        </v-sheet>
      </v-col>
      <v-col
          cols="12"
          lg="7"
          md="7"
      >
        <v-sheet
            min-height="70vh"
            rounded="lg"
        >
          <v-card
              elevation="2"
              class="mx-auto mb-4 d-none d-lg-flex"
              outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="display-1 mb-1 text-wrap">
                  {{ course.title }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-wrap">{{ course.sub_title }}</v-list-item-subtitle>
                <div class="d-flex align-baseline" v-if="course">
                  <v-btn
                      depressed
                      color="success"
                      class="mr-2 my-2"
                      text
                  >
                    <div v-if="course.discount">
                      ৳{{ price }} <span class="red--text"><del v-if="course.is_paid">৳{{ course.price }}</del></span>
                    </div>
                    <div v-else>
                      ৳{{ price }}
                    </div>
                  </v-btn>
                  <div v-if="course.users_count" class="caption grey--text">
                    <v-icon small v-text="'mdi-account-multiple'"></v-icon>
                    {{ course.users_count }}
                  </div>
                </div>
                <div v-if="course">
                  <v-btn
                      depressed
                      color="success"
                      class="mr-2 my-2"
                      :to="{name: 'Pay',params:{courseId:$route.params.courseId, course: course}}"
                      small
                      v-if="!free"
                      v-show="!subscribed"
                  >
                    Buy this course
                  </v-btn>
                  <v-btn
                      depressed
                      color="success"
                      class="mr-2 my-2"
                      small
                      v-else
                      @click="enroll"
                      v-show="!subscribed"
                  >
                    Enroll
                  </v-btn>
                  <v-btn
                      depressed
                      color="primary"
                      class="mr-2 my-2"
                      :to="{name: 'Course',params:{courseId:$route.params.courseId}}"
                      small
                  >
                    View course
                  </v-btn>
                </div>
                <div v-else>
                  <v-skeleton-loader
                      type="heading: heading"
                  ></v-skeleton-loader>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card
              class="mx-auto mb-4"
              elevation="2"
              outlined
          >
            <v-card-title>About this course</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text v-html="course.description">

            </v-card-text>
          </v-card>
          <v-card
              class="mx-auto mb-4"
              elevation="2"
              outlined
          >
            <v-card-title>Requirements</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text v-html="course.requirements">
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
//    import axios from 'axios'
import CourseMenuPreview from './CourseMenuPreview.vue'
import axios from "axios";
import Swal from "sweetalert2";
import {mapGetters} from "vuex";

export default {
  components: {
    CourseMenuPreview,
  },
  data() {
    return {
      course: '',
      topics: [],
      rating: 4.5
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    }),
    introductionVideo() {
      if (this.topics[0] && this.topics[0].contents[0] && this.topics[0].contents[0].video) {
        return this.topics[0].contents[0].video.link
      } else return null;
    },
    totalVideos() {
      let topics = this.topics
      let total = 0
      if (topics) {
        topics.forEach((topic) => {
          total += topic.total_video_count
        })
      }
      return total
    },
    totalExams() {
      let topics = this.topics
      let total = 0
      if (topics) {
        topics.forEach((topic) => {
          total += topic.total_exam_count
        })
      }
      return total
    },
    totalPdfs() {
      let topics = this.topics
      let total = 0
      if (topics) {
        topics.forEach((topic) => {
          total += topic.total_pdf_count
        })
      }
      return total
    },
    totalNotes() {
      let topics = this.topics
      let total = 0
      if (topics) {
        topics.forEach((topic) => {
          total += topic.total_note_count
        })
      }
      return total
    },
    price() {
      if (this.course.subscribed) {
        return 'Enrolled'
      }
      let price = this.course.price
      let discount = this.course.discount || 0
      return this.course.is_paid ? price - discount : 'Free'
    },
    free() {
      return this.price === 'Free'
    },
    subscribed() {
      return this.course.subscribed
    }
  },
  methods: {
    enroll() {
      if (this.authenticated) {
        const url = 'payment'
        let data = {
          phone: this.user.phone,
          transaction_id: this.uniqueId(10),
          amount: 0,
          course_id: this.course.id,
        };
        axios.post(url, data).then(() => {
          Swal.fire(
              'Successful!',
              'You have full access to this course now.',
              'success'
          );
          this.loadCourse()
        }).catch((error) => {
          console.log(error)
        })
      } else {
        this.$router.push({name: 'Login'})
      }
    },
    uniqueId(length) {
      let result = '';
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
  },
  created() {
    this.loadCourseOnly()
    this.loadTopics()
  },
  mounted() {
    scrollTo(0,0)
  }
}
</script>
<style lang="scss">
.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  border-radius: 5px;
}

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%
}
</style>