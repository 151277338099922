<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-list color="transparent">

    <v-list-group
        no-action
        v-for="(topic, index) in topics"
        :key="topic.name+topic.id"
        :value="!index"
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>{{ topic.name }}</v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
          v-for="content in topic.contents"
          :key="content.id+content.id"
          link
          class="ml-0 pl-4"
      >
        <v-list-item-icon v-if="content.video">
          <v-icon v-text="'mdi-video'"></v-icon>
        </v-list-item-icon>
        <v-list-item-title v-if="content.video" v-text="content.video.title" @click="dialog=true"></v-list-item-title>

        <v-list-item-icon v-if="content.exam">
          <v-icon v-text="'mdi-newspaper'"></v-icon>
        </v-list-item-icon>
        <v-list-item-title v-if="content.exam" v-text="content.exam.title" @click="dialog=true">
        </v-list-item-title>

        <v-list-item-icon v-if="content.pdf">
          <v-icon v-text="'mdi-file-pdf'"></v-icon>
        </v-list-item-icon>
        <v-list-item-title v-if="content.pdf" v-text="content.pdf.title" @click="dialog=true"></v-list-item-title>

        <v-list-item-icon v-if="content.note">
          <v-icon v-text="'mdi-note-outline'"></v-icon>
        </v-list-item-icon>
        <v-list-item-title v-if="content.note" v-text="content.note.title" @click="dialog=true"></v-list-item-title>

      </v-list-item>
    </v-list-group>
    <v-dialog
        v-model="dialog"
        max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          View this content?
        </v-card-title>
        <v-card-text>
          Click 'Go to course' to view this item.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              color="green darken-1"
              text
              :to="{name: 'Course', params:{courseId: course.id}}"
          >
            Go to course
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list>
</template>
<script>
export default {
  props: ['topics','course'],
  data: () => ({
    dialog: false,
  }),
  methods: {},
  mounted() {
  }
}
</script>